import React, { useEffect, useState } from "react"
import { getLs, setLs } from "../../utils/localStorage"

interface Props {
  orderId: string
  orderSubtotal: number
  currency: string
  couponCode?: string
}

const ShareASale = ({
  orderId,
  orderSubtotal,
  currency,
  couponCode,
}: Props) => {
  const [renderAffiliateTracking, setRenderAffiliateTracking] = useState(false)

  const parsedSubtotal = (orderSubtotal / 100).toFixed(2)

  useEffect(() => {
    const affiliateOrderTracked = getLs("affiliateOrderTracked")

    if (
      !(affiliateOrderTracked === orderId) &&
      process.env.GATSBY_SHAREASALE_ACTIVE === "true"
    ) {
      setRenderAffiliateTracking(true)
    }

    setLs("affiliateOrderTracked", orderId)
  }, [])

  const renderScript = () => (
    <>
      <img
        src={`https://www.shareasale.com/sale.cfm?tracking=${orderId}&amount=${parsedSubtotal}&merchantID=${
          process.env.GATSBY_SHAREASALE_MERCHANT_ID
        }&transtype=sale&currency=${currency.toUpperCase()}&couponcode=${couponCode}`}
        width="1"
        height="1"
      />
      <script
        src="https://www.dwin1.com/58409.js"
        type="text/javascript"
        defer={true}
      ></script>
    </>
  )

  return renderAffiliateTracking ? renderScript() : null
}

export default ShareASale
