import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import { useStaticQuery, graphql, PageProps } from "gatsby"

import Layout from "../../../components/Layout"
import { useAuthState } from "../../../contexts/authContext"
import styled from "styled-components"
import SimpleContent, {
  ContentSection,
} from "../../../components/sections/SimpleContent"
import { Product } from "../../../ts/interfaces"
import { getStripeId } from "../../../utils/stripejs"
import ShareASale from "../../../checkout/components/ShareASale"
import useClearCart from "../../../checkout/hooks/useClearCart"
import { useUnbounceTracking } from "../../../hooks/useUnbounceTracking"
import { removeLs } from "../../../utils/localStorage"

interface LocationState {
  stripeConfirmationCode: string
  cardHolderName: string
  cardHolderEmail: string
  productIds: string[]
  isSubscription?: boolean
  subtotal: number
  currency: string
  couponCode?: string
}

const FeatureWrapper = styled.div`
  & p {
    font-size: 1.1875rem;
    line-height: 2rem;
    margin: 1rem 0;
  }
`

const replaceMergeFields = (
  text?: string,
  customer?: string,
  email?: string,
  confirmationCode?: string
) => {
  if (!text || text === "") {
    return ""
  }

  text = text.split("*NAME*").join(customer || "")
  text = text.split("*EMAIL*").join(email || "")
  text = text
    .split("*CONFIRMATION*")
    .join(
      `<span class="confirmation-code" data-testid="confirmation-code">${confirmationCode}</span>` ||
        ""
    )
  return text
}

const confirmation = (props: PageProps) => {
  if (!props.location.state) {
    if (typeof window !== `undefined`) {
      navigate("/")
    } else {
      return <div></div>
    }
  } else {
    const state = useAuthState()
    const {
      stripeConfirmationCode,
      cardHolderEmail,
      cardHolderName,
      productIds,
      isSubscription,
      subtotal,
      currency,
      couponCode,
    } = props.location && (props.location.state as LocationState)

    const [clearCart] = useClearCart()

    useEffect(() => {
      removeLs("selectedPaymentMethodId")
      clearCart()
    }, [])

    useUnbounceTracking()

    const { strapi } = useStaticQuery(
      graphql`
        query {
          strapi {
            paymentSuccess {
              fields {
                content
                align
                core {
                  anchorTag
                  title
                  titleColor
                  subtitle
                  subtitleColor
                  backgroundColor
                  animate
                  marginTop
                  marginBottom
                  underlineTitle
                  titleAlign
                  divider
                  backgroundImage {
                    url
                    alternativeText
                    imageFile {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: NONE
                          layout: FULL_WIDTH
                          quality: 80
                        )
                      }
                    }
                  }
                }
                featuredImage {
                  url
                  alternativeText
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: NONE
                        layout: FULL_WIDTH
                        quality: 80
                      )
                    }
                  }
                }
              }
            }
            subscriptionSuccess {
              fields {
                content
                align
                core {
                  anchorTag
                  title
                  titleColor
                  subtitle
                  subtitleColor
                  backgroundColor
                  animate
                  marginTop
                  marginBottom
                  underlineTitle
                  titleAlign
                  divider
                  backgroundImage {
                    url
                    alternativeText
                    imageFile {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: NONE
                          layout: FULL_WIDTH
                          quality: 80
                        )
                      }
                    }
                  }
                }
                featuredImage {
                  url
                  alternativeText
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: NONE
                        layout: FULL_WIDTH
                        quality: 80
                      )
                    }
                  }
                }
              }
            }
            products {
              id
              name
              stripeId
              stripeTestId
              isBilledMonthly
              isBilledYearly
              postPurchaseContent {
                id
                content
                align
                maxWidth
                largeFormat
                inlineMedia {
                  referenceName
                  rounded
                  align
                  width
                  youtubeId
                  youtubeMobileId
                  image {
                    url
                    alternativeText
                    imageFile {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: NONE
                          layout: FULL_WIDTH
                          quality: 80
                        )
                      }
                    }
                  }
                  mobileImage {
                    url
                    alternativeText
                    imageFile {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: NONE
                          layout: FULL_WIDTH
                          quality: 80
                        )
                      }
                    }
                  }
                }
                buttons {
                  button {
                    id
                    title
                    type
                    link {
                      __typename
                      ... on STRAPI_ComponentNavigationMainNav {
                        navItemPage {
                          id
                          slug
                        }
                        navItemLandingPage {
                          id
                          slug
                        }
                        navItemName
                      }
                      ... on STRAPI_ComponentNavigationProduct {
                        id
                        addToCart
                        product {
                          id
                          stripeId
                          stripeTestId
                          slug
                        }
                        linkText
                      }
                      ... on STRAPI_ComponentNavigationHyperlink {
                        url
                      }
                      ... on STRAPI_ComponentZonesQuiz {
                        id
                        quizId
                      }
                    }
                  }
                }

                core {
                  anchorTag
                  title
                  titleColor
                  subtitle
                  subtitleColor
                  backgroundColor
                  animate
                  marginTop
                  marginBottom
                  underlineTitle
                  smallSubTitle
                  titleAlign
                  divider
                  backgroundImage {
                    url
                    alternativeText
                    imageFile {
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: NONE
                          layout: FULL_WIDTH
                          quality: 80
                        )
                      }
                    }
                  }
                }
                featuredImage {
                  url
                  alternativeText
                  imageFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: NONE
                        layout: FULL_WIDTH
                        quality: 70
                      )
                    }
                  }
                }
                featuredVideo {
                  youtubeId
                  youtubeMobileId
                }
              }
            }
          }
        }
      `
    )

    const products: Product[] = !productIds
      ? []
      : strapi.products.filter((prd: Product) => {
          const stripeId = getStripeId(prd.stripeId, prd.stripeTestId)
          return !stripeId ? false : productIds.includes(stripeId)
        })

    const subFields: ContentSection = strapi?.subscriptionSuccess?.fields
    const normalFields: ContentSection = strapi?.paymentSuccess?.fields

    const firstName = state.firstName
    const email = state.email

    const [year, comfCode] = stripeConfirmationCode
      ? stripeConfirmationCode.split("-")
      : ["", ""]

    const customer = firstName || state.firstName || cardHolderName
    const em = email || state.email || cardHolderEmail

    const fields = isSubscription ? subFields : normalFields

    const cnt = JSON.parse(JSON.stringify(fields))

    cnt.core.title = replaceMergeFields(
      fields.core.title,
      customer,
      em,
      comfCode
    )

    cnt.core.subtitle = replaceMergeFields(
      fields.core.subtitle,
      customer,
      em,
      comfCode
    )

    cnt.content = replaceMergeFields(fields.content, customer, em, comfCode)

    const accountLink =
      '\n\rView your <a id="account-order-link" title="Account History" href="/user">account and order history</a>.'
    if (state.isLoggedIn() && fields.content.indexOf(accountLink) === -1) {
      cnt.content += accountLink
    }

    const postPurchaseContent =
      (products &&
        products.length &&
        products
          .filter(p => p.postPurchaseContent)
          .map(p => p.postPurchaseContent)) ||
      []

    const deDupedPostPurchaseContent = postPurchaseContent.filter(
      (p, index, arr) => p && arr.findIndex((t: any) => t.id === p.id) == index
    )

    return (
      <Layout hasHero={false}>
        <FeatureWrapper id={`section_payment_success`}>
          <SimpleContent {...cnt} wideImage={true} />
        </FeatureWrapper>
        {deDupedPostPurchaseContent &&
          deDupedPostPurchaseContent.length > 0 &&
          deDupedPostPurchaseContent.map(content => (
            <section
              id={`order_confirmed_p${content!.id}`}
              key={content!.id}
              className="white"
              style={{
                position: "relative",
                marginTop: content!.core?.marginTop
                  ? `${content!.core.marginTop}rem`
                  : 0,
                marginBottom: content!.core?.marginBottom
                  ? `${content!.core.marginBottom}rem`
                  : 0,
              }}
            >
              <SimpleContent {...content!} />
            </section>
          ))}
        <ShareASale
          orderId={stripeConfirmationCode}
          orderSubtotal={subtotal}
          currency={currency}
          couponCode={couponCode}
        />
      </Layout>
    )
  }
}

export default confirmation
