import { useEffect } from "react"

declare global {
  interface Window {
    _ubad: string
    _ubaq: any[]
  }
}

export const useUnbounceTracking = (): void => {
  useEffect(() => {
    // Ensure this code is not executed during the Gatsby build process
    if (typeof window === "undefined") {
      return
    }

    window._ubad = "get.sens.ai"
    window._ubaq = window._ubaq || []
    window._ubaq.push(["trackGoal"])

    const ubScript = document.createElement("script")
    ubScript.type = "text/javascript"
    ubScript.src = `//${window._ubad}/_ub/static/ets/t.js`
    const s = document.getElementsByTagName("script")[0]
    if (s.parentNode) {
      s.parentNode.insertBefore(ubScript, s)
    }

    return () => {
      // Remove the script from the DOM when the component is unmounted
      if (ubScript.parentNode) {
        ubScript.parentNode.removeChild(ubScript)
      }
    }
  }, [])
}
